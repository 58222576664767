import React from "react";
import CertificateCard from '../../components/certificate-card'
import LayoutB1 from "../../components/layout-b1"

export default class SearchResult extends React.Component{
  render() {
    return(
      <LayoutB1 location={this.props.location}>
        <CertificateCard />
      </LayoutB1>
    )
  }
}